var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isValid
        ? _c("form-summary", {
            staticClass: "form-errors alert alert-danger",
            attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
          })
        : _vm._e(),
      _c("CForm", [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c(
            "div",
            { staticClass: "mb-0 d-flex flex-column flex-sm-row" },
            [
              _c("CInput", {
                staticClass: "w-100 mr-2",
                attrs: {
                  label: "VAT (BTW) Number:",
                  placeholder: "VAT (BTW) Number",
                  lazy: false,
                  value: _vm.$v.form.btw_nummer.$model,
                  disabled: _vm.invoiceCount > 0,
                  isValid: _vm.checkIfValid("btw_nummer"),
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.$v.form.btw_nummer, "$model", $event)
                  },
                  focusout: _vm.fixVat,
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          {
                            attrs: {
                              color: "info",
                              disabled:
                                _vm.checkSpinner ||
                                !_vm.$v.form.btw_nummer.$model ||
                                !_vm.isValidVat(_vm.form.btw_nummer),
                            },
                            on: { click: _vm.getCompanyInfo },
                          },
                          [
                            _vm.checkSpinner
                              ? _c("CSpinner", { attrs: { size: "sm" } })
                              : _vm._e(),
                            _vm._v(" Get Company Info "),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "form-group w-100" },
                [
                  _vm.form.btw_nummer && _vm.isValidVat(_vm.form.btw_nummer)
                    ? [
                        _c("label", { staticClass: "d-none d-sm-block" }, [
                          _vm._v(" "),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "btn p-1",
                            attrs: {
                              href: `https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?lang=NL&ondernemingsnummer=${_vm.form.btw_nummer}`,
                              target: "blank",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/logo-kbo.jpg"),
                                height: "28",
                              },
                            }),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "CAlert",
                {
                  attrs: {
                    show: _vm.allRestaurantByVAT.length > 0,
                    color: "info",
                  },
                },
                [
                  _c("CIcon", {
                    staticClass: "mr-2",
                    attrs: { height: 24, name: "cil-info" },
                  }),
                  _c("strong", [
                    _vm._v(
                      "Restaurants that have " +
                        _vm._s(_vm.form.btw_nummer) +
                        " VAT (BTW) Number:"
                    ),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "pl-3 mt-2 mb-0" },
                    _vm._l(_vm.allRestaurantByVAT, function (restaurant) {
                      return _c(
                        "li",
                        { key: restaurant.value },
                        [
                          _c(
                            "CLink",
                            {
                              attrs: {
                                to: {
                                  name: "Edit Restaurant",
                                  params: { id: restaurant.value },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(restaurant.label) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-3 d-flex flex-column flex-md-row" },
            [
              _c("CInput", {
                staticClass: "w-100 mr-2",
                attrs: {
                  label: "Company Name:",
                  placeholder: "Company Name",
                  lazy: false,
                  value: _vm.$v.form.firma_naam.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.$v.form.firma_naam, "$model", $event)
                  },
                },
              }),
              _c("CInput", {
                staticClass: "w-100",
                attrs: {
                  label: "Company Type:",
                  placeholder: "Company Type",
                  lazy: false,
                  value: _vm.$v.form.firma_type.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.$v.form.firma_type, "$model", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "address mb-3" },
            [
              _c(
                "div",
                { staticClass: "form-group w-100" },
                [
                  _c(
                    "CLink",
                    {
                      attrs: { color: "info" },
                      on: { click: _vm.copyFromRestaurantAddress },
                    },
                    [
                      _vm.copySucceeded.restaurantAddress
                        ? _c(
                            "span",
                            [
                              _c("CIcon", { attrs: { name: "cil-check-alt" } }),
                              _vm._v(" Copied!"),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c("CIcon", { attrs: { name: "cil-copy" } }),
                              _vm._v(" Copy From Restaurant Address"),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c("CInput", {
                staticClass: "w-100",
                attrs: {
                  id: "verify_firm_address",
                  value: _vm.verified_firm_address,
                  label: "Verify Company Address From Google:",
                  placeholder: "Enter a location",
                  lazy: false,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.verified_firm_address = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          { attrs: { color: "info" } },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-location-pin" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-md-row" },
                [
                  _c("CTextarea", {
                    staticClass: "w-100",
                    attrs: {
                      rows: "2",
                      label: "Address:",
                      placeholder: "Street name and house number",
                      lazy: false,
                      value: _vm.$v.form.firma_mz_adres.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.firma_mz_adres,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-md-row" },
                [
                  _c("CInput", {
                    staticClass: "w-100 mr-2",
                    attrs: {
                      label: "Postal Code:",
                      placeholder: "Zip or Postal Code",
                      lazy: false,
                      value: _vm.$v.form.firma_mz_postcode.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.firma_mz_postcode,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                  _c("CInput", {
                    staticClass: "w-100 mr-2",
                    attrs: {
                      label: "City:",
                      placeholder: "City",
                      lazy: false,
                      value: _vm.$v.form.firma_mz_city.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.firma_mz_city,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-3 d-flex flex-column flex-md-row" },
            [
              _c("CInput", {
                staticClass: "w-100 mr-md-2",
                attrs: {
                  label: "Bank Account Holder:",
                  placeholder: "Bank Account Holder",
                  lazy: false,
                  value: _vm.$v.form.bank_rekeninghouder.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.$v.form.bank_rekeninghouder,
                      "$model",
                      $event
                    )
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "w-100 d-flex flex-row" },
                [
                  _c("CInput", {
                    staticClass: "w-100 mr-2",
                    attrs: {
                      label: "Bank Name:",
                      placeholder: "Bank Name",
                      lazy: false,
                      value: _vm.$v.form.bank_naam.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.$v.form.bank_naam, "$model", $event)
                      },
                    },
                  }),
                  _c("CInput", {
                    staticClass: "w-100",
                    attrs: {
                      label: "Bank Account Number (IBAN):",
                      placeholder: "Bank Account Number (IBAN)",
                      lazy: false,
                      value: _vm.$v.form.bank_rekeningnummer.$model,
                      isValid: _vm.checkIfValid("bank_rekeningnummer"),
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.bank_rekeningnummer,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("h6", { staticClass: "border-bottom pb-1" }, [
            _vm._v("Contact Person 1:"),
          ]),
          _c(
            "div",
            { staticClass: "mb-3 d-flex flex-column flex-md-row" },
            [
              _c("CInput", {
                staticClass: "w-100 mr-md-2",
                attrs: {
                  label: "Name:",
                  placeholder: "Name",
                  lazy: false,
                  value: _vm.$v.form.eigenaar1_naam.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.$v.form.eigenaar1_naam,
                      "$model",
                      $event
                    )
                  },
                },
              }),
              _c("div", { staticClass: "w-100 d-flex flex-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group w-100 mr-2" },
                  [
                    _c(
                      "label",
                      { staticClass: "d-block" },
                      [
                        _vm._v(" Mobile Phone: "),
                        _c(
                          "CLink",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: "Copy from Restaurant Mobile Number",
                                expression:
                                  "'Copy from Restaurant Mobile Number'",
                              },
                            ],
                            attrs: { color: "info" },
                            on: { click: _vm.copyFromRestaurantGsm },
                          },
                          [
                            _vm.copySucceeded.restaurantGsm
                              ? _c(
                                  "span",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-check-alt" },
                                    }),
                                    _vm._v(" Copied!"),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-copy" },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("CInput", {
                      staticClass: "w-100 mr-2",
                      attrs: {
                        placeholder: "Mobile Phone",
                        lazy: false,
                        value: _vm.$v.form.eigenaar1_gsm.$model,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.$v.form.eigenaar1_gsm,
                            "$model",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group w-100 mr-2" },
                  [
                    _c(
                      "label",
                      { staticClass: "d-block" },
                      [
                        _vm._v(" Email: "),
                        _c(
                          "CLink",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: "Copy from Restaurant Email",
                                expression: "'Copy from Restaurant Email'",
                              },
                            ],
                            attrs: { color: "info" },
                            on: { click: _vm.copyFromRestaurantEmail },
                          },
                          [
                            _vm.copySucceeded.restaurantEmail
                              ? _c(
                                  "span",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-check-alt" },
                                    }),
                                    _vm._v(" Copied!"),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-copy" },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("CInput", {
                      staticClass: "w-100 email",
                      attrs: {
                        placeholder: "Email",
                        lazy: false,
                        value: _vm.$v.form.eigenaar1_email.$model,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.$v.form.eigenaar1_email,
                            "$model",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("h6", { staticClass: "border-bottom pb-1" }, [
            _vm._v("Contact Person 2:"),
          ]),
          _c(
            "div",
            { staticClass: "mb-3 d-flex flex-column flex-md-row" },
            [
              _c("CInput", {
                staticClass: "w-100 mr-md-2",
                attrs: {
                  label: "Name:",
                  placeholder: "Name",
                  lazy: false,
                  value: _vm.$v.form.eigenaar2_naam.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.$v.form.eigenaar2_naam,
                      "$model",
                      $event
                    )
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "w-100 d-flex flex-row" },
                [
                  _c("CInput", {
                    staticClass: "w-100 mr-2",
                    attrs: {
                      label: "Mobile Phone:",
                      placeholder: "Mobile Phone",
                      lazy: false,
                      value: _vm.$v.form.eigenaar2_gsm.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.eigenaar2_gsm,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                  _c("CInput", {
                    staticClass: "w-100 email",
                    attrs: {
                      label: "Email:",
                      placeholder: "Email",
                      lazy: false,
                      value: _vm.$v.form.eigenaar2_email.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.$v.form.eigenaar2_email,
                          "$model",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column flex-md-row" },
            [
              _c("CTextarea", {
                staticClass: "w-100 mr-md-2",
                attrs: {
                  rows: "2",
                  label: "References:",
                  placeholder: "References",
                  lazy: false,
                  value: _vm.$v.form.referenties.$model,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.$v.form.referenties, "$model", $event)
                  },
                },
              }),
              _c("CSelect", {
                staticClass: "w-100",
                attrs: {
                  description:
                    "Please select the originator of the restaurant.",
                  label: "Originator:",
                  placeholder: "Please select..",
                  lazy: false,
                  value: _vm.$v.form.originator.$model,
                  options: _vm.$globalVar.originators,
                  isValid: _vm.checkIfValid("originator"),
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.$v.form.originator, "$model", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }